var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "outer" },
      [
        _c(
          "search-panel",
          {
            attrs: { flex: "", "show-btn": "" },
            on: { getList: _vm.getSearchList },
          },
          [
            _c(
              "div",
              [
                _c("p", [_vm._v("商品名称")]),
                _c("el-input", {
                  staticStyle: { width: "240px" },
                  attrs: { size: "mini", placeholder: "请输入商品名称" },
                  model: {
                    value: _vm.goods_name,
                    callback: function ($$v) {
                      _vm.goods_name = $$v
                    },
                    expression: "goods_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("p", [_vm._v("商品ID")]),
                _c("el-input", {
                  staticStyle: { width: "240px" },
                  attrs: {
                    size: "mini",
                    placeholder: "请输入商品ID",
                    type: "number",
                  },
                  model: {
                    value: _vm.goods_id,
                    callback: function ($$v) {
                      _vm.goods_id = $$v
                    },
                    expression: "goods_id",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("p", [_vm._v("发布状态")]),
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "240px" },
                    attrs: {
                      placeholder: "拼团状态",
                      size: "mini",
                      clearable: "",
                    },
                    model: {
                      value: _vm.status,
                      callback: function ($$v) {
                        _vm.status = $$v
                      },
                      expression: "status",
                    },
                  },
                  _vm._l(_vm.stateSelectArr, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: {
                  slot: "button-right",
                  plain: "",
                  type: "primary",
                  size: "mini",
                },
                on: { click: () => (_vm.isShowExplain = true) },
                slot: "button-right",
              },
              [_vm._v(" 功能说明 ")]
            ),
            _c(
              "el-button",
              {
                staticStyle: { "margin-left": "35px" },
                attrs: { slot: "button-right", type: "primary", size: "mini" },
                on: { click: _vm.add },
                slot: "button-right",
              },
              [_vm._v(" 添加拼团商品 ")]
            ),
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData,
              border: "",
              "element-loading-text": "拼命加载中",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "goodsName",
                label: "商品名称",
                "min-width": "100",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "goodsId", label: "商品ID", width: "90" },
            }),
            _c("el-table-column", {
              attrs: { prop: "goodsId", label: "拼团类型", width: "90" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(row.groupType === 1 ? "常规拼团" : "凑单拼团")
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "成团要求", width: "90", prop: "groupCount" },
            }),
            _c("el-table-column", {
              attrs: { label: "活动时间", "min-width": "165" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.dayFormat(scope.row.beginTime)) +
                            " - " +
                            _vm._s(_vm.dayFormat(scope.row.endTime)) +
                            " "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "minSkuPrice", label: "原价" },
            }),
            _c("el-table-column", {
              attrs: { prop: "groupPrice", label: "拼团价" },
            }),
            _c("el-table-column", {
              attrs: { prop: "openGroupNum", label: "开团数" },
            }),
            _c("el-table-column", {
              attrs: { prop: "successGroupNum", label: "成团数" },
            }),
            _c("el-table-column", {
              attrs: { prop: "shareGroupNum", label: "累计分享数" },
            }),
            _c("el-table-column", {
              attrs: { prop: "saleGroupNum", label: "销量（GMV）" },
            }),
            _c("el-table-column", {
              attrs: { label: "状态" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.stateSelectArr.find(
                              (item) =>
                                item.value ===
                                _vm.comTime(
                                  scope.row.beginTime,
                                  scope.row.endTime
                                )
                            ).label
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "操作", width: "140", fixed: "right" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.readDetail(scope.row)
                            },
                          },
                        },
                        [_vm._v(" 详情 ")]
                      ),
                      _vm.comTime(scope.row.beginTime, scope.row.endTime) ===
                        0 ||
                      _vm.comTime(scope.row.beginTime, scope.row.endTime) === 1
                        ? _c(
                            "el-button",
                            {
                              staticClass: "red",
                              staticStyle: { "margin-left": "20px" },
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.stopLabel(scope.row)
                                },
                              },
                            },
                            [_vm._v(" 终止活动 ")]
                          )
                        : _vm._e(),
                      _vm.comTime(scope.row.beginTime, scope.row.endTime) === 2
                        ? _c(
                            "el-button",
                            {
                              staticClass: "red",
                              staticStyle: { "margin-left": "20px" },
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteGoods(scope.row)
                                },
                              },
                            },
                            [_vm._v(" 删除 ")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c("el-pagination", {
          attrs: {
            "current-page": _vm.pagination.currentPage,
            "page-sizes": [10, 20, 30, 50],
            "page-size": _vm.pagination.nowPageSize,
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.pagination.count,
          },
          on: {
            "size-change": _vm.handleSizeChange,
            "current-change": _vm.handleCurrentChange,
          },
        }),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "拼团详情",
              visible: _vm.groupDetails,
              width: "840px",
              "before-close": () => (_vm.groupDetails = false),
            },
            on: {
              "update:visible": function ($event) {
                _vm.groupDetails = $event
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "group-detail" },
              [
                _c(
                  "el-row",
                  { staticStyle: { "margin-bottom": "20px" } },
                  [
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c("div", { staticClass: "grid-content bg-purple" }, [
                        _c("div", { staticClass: "group-detail-good" }, [
                          _c("img", {
                            attrs: {
                              src: _vm.groupDetailData.imageUrls,
                              alt: "",
                            },
                          }),
                          _c("div", { staticClass: "group-detail-good-box" }, [
                            _c(
                              "div",
                              { staticClass: "group-detail-good-txt" },
                              [
                                _vm._v(
                                  " 商品名称：" +
                                    _vm._s(_vm.groupDetailData.goodsName) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "group-detail-good-txt" },
                              [
                                _vm._v(
                                  " 成团要求：" +
                                    _vm._s(_vm.groupDetailData.groupCount) +
                                    " " +
                                    _vm._s(
                                      _vm.groupDetailData.groupType === 1
                                        ? "人"
                                        : "件"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "group-detail-good-txt" },
                              [_vm._v(" 活动时间： ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "group-detail-good-txt" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.groupDetailData.beginTime) +
                                    " - " +
                                    _vm._s(_vm.groupDetailData.endTime) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        { staticClass: "grid-content bg-purple-light" },
                        [
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loading,
                                  expression: "loading",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.groupDetailData.goodsSpec,
                                border: "",
                                "element-loading-text": "拼命加载中",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { prop: "skuName", label: "规格名称" },
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "skuPrice", label: "原价" },
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "groupPrice", label: "团购价" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("p", { staticClass: "group-detail-statistics" }, [
                      _vm._v(" 活动统计 "),
                    ]),
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.groupGoodsStaticList,
                          border: "",
                          "element-loading-text": "拼命加载中",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { prop: "successNums", label: "已成团" },
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "groupingNums", label: "正在拼团" },
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "sumSales", label: "累计销售额" },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "totalSaleNums",
                            label: "累计售出（件）",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "particNums", label: "参与人数" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "惠拼团功能说明",
              visible: _vm.isShowExplain,
              width: "50%",
              "before-close": () => (_vm.isShowExplain = false),
            },
            on: {
              "update:visible": function ($event) {
                _vm.isShowExplain = $event
              },
            },
          },
          [
            _c("div", { staticClass: "explain" }, [
              _c("div", { staticClass: "explain-int" }, [
                _c("p", [_vm._v("功能简介：")]),
                _c("div", [
                  _vm._v(
                    "商家可以对商品设置团购价，并通过拼团的方式进行团购，用户可邀请好友参与拼团。成团后，商家可以根据用户参团情况制定进货销售策略。此活动可以让用户享受低价购买的同时，让身边朋友也参与购买。商家通过该活动可达到营销及预售的目的。"
                  ),
                ]),
              ]),
              _c("div", { staticClass: "explain-int" }, [
                _c("p", [_vm._v("功能说明：")]),
                _c("div", [_vm._v("团购价：显示商品的团购价。")]),
                _c("div", [
                  _vm._v(
                    "活动时间：设置活动的开始和结束时间。活动时间仅控制开团时间，如果活动时间结束，已开团但未达到成团时限的商品任可继续参团！"
                  ),
                ]),
                _c("div", [
                  _vm._v(
                    "成团时限：团长开团后，需在24小时内完成组团人数要求，否则将拼团失败，订单将退款。"
                  ),
                ]),
                _c("div", [_vm._v("活动状态：展示该活动当前的活动状态。")]),
                _c("div", [
                  _vm._v(
                    "终止活动：结束当前活动。注：结束活动后，将无法恢复。"
                  ),
                ]),
                _c("div", [_vm._v("每人限购：输入该砍价商品每人限购的数量。")]),
                _c("div", [_vm._v("活动商品数：编辑每个商品的活动库存。")]),
                _c("div", [
                  _vm._v("删除：永久删除该活动，仅适用于已结束的活动。"),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "explain-footer" },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "130px" },
                      attrs: { type: "primary" },
                      on: { click: () => (_vm.isShowExplain = false) },
                    },
                    [_vm._v(" 确定 ")]
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }