<template>
  <div class="app-container">
    <div class="outer">
      <search-panel
        flex
        show-btn
        @getList="getSearchList"
      >
        <div>
          <p>商品名称</p>
          <el-input
            v-model="goods_name"
            size="mini"
            style="width: 240px"
            placeholder="请输入商品名称"
          />
        </div>
        <div>
          <p>商品ID</p>
          <el-input
            v-model="goods_id"
            size="mini"
            style="width: 240px"
            placeholder="请输入商品ID"
            type="number"
          />
        </div>
        <div>
          <p>发布状态</p>
          <el-select
            v-model="status"
            placeholder="拼团状态"
            size="mini"
            style="width: 240px"
            clearable
          >
            <el-option
              v-for="item in stateSelectArr"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>

        <el-button
          slot="button-right"
          plain
          style="margin-left:10px"
          type="primary"
          size="mini"
          @click="() => isShowExplain=true"
        >
          功能说明
        </el-button>

        <el-button
          slot="button-right"
          style="margin-left: 35px"
          type="primary"
          size="mini"
          @click="add"
        >
          添加拼团商品
        </el-button>
      </search-panel>
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        element-loading-text="拼命加载中"
        style="width: 100%"
      >
        <el-table-column
          prop="goodsName"
          label="商品名称"
          min-width="100"
        />
        <el-table-column
          prop="goodsId"
          label="商品ID"
          width="90"
        />
        <el-table-column
          prop="goodsId"
          label="拼团类型"
          width="90"
        >
          <template slot-scope="{row}">
            <span>{{ row.groupType === 1 ? '常规拼团' : '凑单拼团' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="成团要求"
          width="90"
          prop="groupCount"
        />

        <el-table-column
          label="活动时间"
          min-width="165"
        >
          <template slot-scope="scope">
            <span>
              {{ dayFormat(scope.row.beginTime) }} - {{ dayFormat(scope.row.endTime) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="minSkuPrice"
          label="原价"
        />
        <el-table-column
          prop="groupPrice"
          label="拼团价"
        />
        <el-table-column
          prop="openGroupNum"
          label="开团数"
        />
        <el-table-column
          prop="successGroupNum"
          label="成团数"
        />
        <el-table-column
          prop="shareGroupNum"
          label="累计分享数"
        />
        <el-table-column
          prop="saleGroupNum"
          label="销量（GMV）"
        />
        <el-table-column label="状态">
          <template slot-scope="scope">
            {{
              stateSelectArr.find((item) => item.value === comTime(scope.row.beginTime, scope.row.endTime))
                .label
            }}
          </template>
        </el-table-column>

        <el-table-column
          label="操作"
          width="140"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="readDetail(scope.row)"
            >
              详情
            </el-button>
            <el-button
              v-if="comTime(scope.row.beginTime,scope.row.endTime) ===0 || comTime(scope.row.beginTime,scope.row.endTime)===1"
              type="text"
              class="red"
              style="margin-left: 20px"
              @click="stopLabel(scope.row)"
            >
              终止活动
            </el-button>
            <el-button
              v-if="comTime(scope.row.beginTime,scope.row.endTime) ===2"
              type="text"
              class="red"
              style="margin-left: 20px"
              @click="deleteGoods(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="pagination.currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pagination.nowPageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.count"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />

      <!--拼团详情-->
      <el-dialog
        title="拼团详情"
        :visible.sync="groupDetails"
        width="840px"
        :before-close="() => (groupDetails = false)"
      >
        <div class="group-detail">
          <el-row style="margin-bottom: 20px">
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <div class="group-detail-good">
                  <img
                    :src="groupDetailData.imageUrls"
                    alt=""
                  />
                  <div class="group-detail-good-box">
                    <div class="group-detail-good-txt">
                      商品名称：{{ groupDetailData.goodsName }}
                    </div>
                    <div class="group-detail-good-txt">
                      成团要求：{{ groupDetailData.groupCount }} {{ groupDetailData.groupType === 1 ? '人' : '件' }}
                    </div>
                    <div class="group-detail-good-txt">
                      活动时间：
                    </div>
                    <div class="group-detail-good-txt">
                      {{ groupDetailData.beginTime }} -
                      {{ groupDetailData.endTime }}
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple-light">
                <el-table
                  v-loading="loading"
                  :data="groupDetailData.goodsSpec"
                  border
                  element-loading-text="拼命加载中"
                  style="width: 100%"
                >
                  <el-table-column
                    prop="skuName"
                    label="规格名称"
                  />
                  <el-table-column
                    prop="skuPrice"
                    label="原价"
                  />
                  <el-table-column
                    prop="groupPrice"
                    label="团购价"
                  />
                </el-table>
              </div>
            </el-col>
          </el-row>
          <div>
            <p class="group-detail-statistics">
              活动统计
            </p>
            <el-table
              v-loading="loading"
              :data="groupGoodsStaticList"
              border
              element-loading-text="拼命加载中"
              style="width: 100%"
            >
              <el-table-column
                prop="successNums"
                label="已成团"
              />
              <el-table-column
                prop="groupingNums"
                label="正在拼团"
              />
              <el-table-column
                prop="sumSales"
                label="累计销售额"
              />
              <el-table-column
                prop="totalSaleNums"
                label="累计售出（件）"
              />
              <el-table-column
                prop="particNums"
                label="参与人数"
              />
            </el-table>
          </div>
        </div>
      </el-dialog>
      <!--拼团详情-->
      <el-dialog
        title="惠拼团功能说明"
        :visible.sync="isShowExplain"
        width="50%"
        :before-close="() => (isShowExplain = false)"
      >
        <div class="explain">
          <div class="explain-int">
            <p>功能简介：</p>
            <div>商家可以对商品设置团购价，并通过拼团的方式进行团购，用户可邀请好友参与拼团。成团后，商家可以根据用户参团情况制定进货销售策略。此活动可以让用户享受低价购买的同时，让身边朋友也参与购买。商家通过该活动可达到营销及预售的目的。</div>
          </div>
          <div class="explain-int">
            <p>功能说明：</p>
            <div>团购价：显示商品的团购价。</div>
            <div>活动时间：设置活动的开始和结束时间。活动时间仅控制开团时间，如果活动时间结束，已开团但未达到成团时限的商品任可继续参团！</div>
            <div>成团时限：团长开团后，需在24小时内完成组团人数要求，否则将拼团失败，订单将退款。</div>
            <div>活动状态：展示该活动当前的活动状态。</div>
            <div>终止活动：结束当前活动。注：结束活动后，将无法恢复。</div>
            <div>每人限购：输入该砍价商品每人限购的数量。</div>
            <div>活动商品数：编辑每个商品的活动库存。</div>
            <div>删除：永久删除该活动，仅适用于已结束的活动。</div>
          </div>
          <div class="explain-footer">
            <el-button
              type="primary"
              style="width: 130px"
              @click="() => (isShowExplain = false)"
            >
              确定
            </el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import qs from 'qs';
import ConfirmBox from '@/components/common/confirm';
import { SHOP_GROUP_STATE } from '../config';
import { strLimit } from '@/assets/js/common';

export default {
  name: 'agricultural',
  data() {
    return {
      goods_name: '', // 商品名称
      goods_id: '', // 商品id
      status: '', // 发布状态
      stateSelectArr: SHOP_GROUP_STATE, // 拼团状态下拉内容
      tableData: [], // 列表内容
      sort: '', // 正序，倒序asc(递增)/desc(递减)
      groupDetails: false, // 拼团详情
      isShowExplain: false, // 功能说明
      loading: false,
      pagination: {
        currentPage: 1,
        nowPageSize: 10,
        count: 0,
      },
      // 拼团详情
      groupDetailData: {
      },
      groupGoodsStaticList: [],
      // 购买明细
      activeName: 'first',
    };
  },
  mounted() {
    this.pagination.currentPage = 1;
    this.getList();
  },
  methods: {
    getSearchList() {
      this.pagination.currentPage = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.pagination.nowPageSize = val;
      this.pagination.currentPage = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getList();
    },
    add() {
      this.$router.push({
        path: '/nb/marketingTools/group/detail',
        query: { type: 'add' },
      });
    },

    // 终止拼团
    stopLabel(row) {
      this.$confirm('终止后，活动将无法恢复。是否提前终止该商品的活动？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios.post(this.$api.groupBook.book_stop, qs.stringify({
          group_id: row.groupId,
        })).then((res) => {
          let r = res || {};
          if (r.code === 0) {
            this.getList();
            this.$message({ type: 'success', message: '终止成功！' });
          }
        });
      }).catch(() => {});
    },
    // 删除拼团
    deleteGoods(row) {
      this.$confirm('是否确定删除活动？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios.post(this.$api.groupBook.book_del, qs.stringify({
          group_id: row.groupId,
        })).then((res) => {
          let r = res || {};
          if (r.code === 0) {
            this.getList();
            this.$message({ type: 'success', message: '删除成功！' });
          }
        });
      }).catch(() => {});
    },

    // 获取列表数据
    getList(index) {
      const _this = this;
      _this.loading = true;
      _this.$axios
        .post(_this.$api.groupBook.book_list, {
          currentPage: _this.pagination.currentPage || index,
          pageSize: _this.pagination.nowPageSize,
          goodsName: _this.goods_name,
          goodsId: _this.goods_id,
          status: _this.status,
        })
        .then((res) => {
          if (res.code === 0) {
            const {
              size, total, current, pages,
            } = res.data;
            _this.pagination = {
              currentPage: Number(current),
              nowPageSize: Number(size),
              count: Number(total),
              // item_total: Number(total)
            };
            _this.tableData = res.data.records;
          } else {
            _this.tableData = [];
          }
          _this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // 查看拼团详情
    readDetail(val) {
      const that = this;
      that.groupDetails = true;
      const url = that.$api.groupBook.book_detail;
      that.$axios({
        method: 'get',
        url,
        params: {
          group_id: val.groupId,
        },
      })
        .then((res) => {
          if (res.code === 0) {
            that.groupGoodsStaticList = [];
            that.groupDetailData = res.data;
            that.groupGoodsStaticList.push(res.data.groupGoodsStatic);
          }
        });
      // that.getStatistics(val.group_id);
    },

    dayFormat(val) {
      return dayjs(parseInt(val)).format('YYYY/MM/DD HH:mm:ss');
    },

    // 计算时间
    comTime(start, end) {
      let now = (new Date()).valueOf();
      let status = null;
      if (start < now < end) {
        status = 1;
      }
      if (now > end) {
        status = 2;
      }
      if (now < start) {
        status = 0;
      }
      console.log(status, '状态。。。');
      return status;
    },

  },
};
</script>

<style lang="scss" scoped>
.publish-img {
  width: 150px;
  height: 100px;
  margin-top: 3px;
  cursor: pointer;
  margin-right: 10px;
}

.cover-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  box-sizing: border-box;
  padding: 10px 35% 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
}

.popover-box {
  width: 500px;
  min-height: 30px;
  max-height: 400px;
  overflow-y: scroll;
  img {
    width: 100%;
    height: auto;
  }
}

.detail-popover {
  color: #50bfff;
  cursor: pointer;
}

//商品详情
.group-detail-good {
  display: flex;
  margin-right: 10px;
  img {
    width: 100px;
    margin-right: 10px;
  }
  .group-detail-good-box {
    padding-top: 10px;
  }
  .group-detail-good-txt {
    margin-bottom: 5px;
  }
}
//拼团详情统计
.group-detail-statistics {
  font-weight: bold;
  margin-bottom: 10px;
}
//功能说明
.explain {
  line-height: 16px;
  p {
    line-height: 25px;
    font-weight: bold;
  }
  .explain-int {
    margin-bottom: 20px;
  }
  .explain-footer {
    text-align: center
  }
}
</style>
